import { GetStaticProps, NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Layout from '../components/Layout'
import { IBasicPageProps } from './[slug]'

import getGlobals from '../services/website/getGlobals'
import getGlobalFlags from '../services/launch-darkly/getGlobalFlags'

import {
  formatFooterFields,
  formatNavFields,
} from '../utils/contentful/format-global-fields'

const NotFound: NextPage<IBasicPageProps> = ({ nav, footer, siteBanner }) => (
  <Layout nav={nav} footer={footer} siteBanner={siteBanner}>
    <h1 className="mt-20 text-center">404 - Page Not Found</h1>
  </Layout>
)

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  const content = await getGlobals()
  const globalFlags = await getGlobalFlags()

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      nav: formatNavFields(content),
      footer: formatFooterFields(content),
      siteBanner: content?.globals.siteBanner,
      globalFlags,
    },
  }
}

export default NotFound
