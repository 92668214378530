import { useEffect } from 'react'
import { useRouter } from 'next/router'
import cx from 'classnames'
import type { Merge } from 'type-fest'

import Meta from './meta'
import Navbar from './Navbar'
import Footer from './Footer'
import SkipToContent from './SkipToContent'
import ChatLauncher from './ChatLauncher'
import { IBasicPageProps } from '../pages/[slug]'

import useAuth from '../hooks/useAuth'

export interface ILayoutNavProps {
  navPrimaryCtaText?: string
  navPrimaryCtaUrl?: string
}

type LayoutProps = Merge<
  IBasicPageProps,
  {
    className?: string
    children: React.ReactNode
    isProtected?: boolean
    subNavBar?: JSX.Element
    centerMainContent?: boolean
    seoTitle?: string
    seoDescription?: string
    seoImage?: string
    hideChat?: boolean
  }
>

const Layout = ({
  children,
  className,
  isProtected,
  subNavBar,
  centerMainContent,
  nav,
  seoTitle,
  seoDescription,
  seoImage,
  footer,
  siteBanner,
  marketingBanner,
  hideChat,
}: LayoutProps) => {
  const { 
    isAuthenticated,
    isLoading: authLoading,
  } = useAuth()
  const router = useRouter()

  useEffect(() => {
    if (authLoading || router.asPath.match('/sign-in')) {
      return
    }

    if (isProtected && !isAuthenticated) {
      router.push({
        pathname: '/sign-in',
        query: { returnUrl: router.asPath }
      })
    }
  }, [isProtected, isAuthenticated, authLoading, router])

  useEffect(() => {
    const scrollbarWidth = window.innerWidth - document.body.clientWidth
    document.body.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`)
  }, [])

  return (
    <>
      {!hideChat ? <ChatLauncher /> : null}
      <Meta
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoImage={seoImage}
      />
      <>
        <SkipToContent />
        {!isProtected || isAuthenticated ? (
          <>
            {!!nav || subNavBar ? (
              <header
                className={cx('bg-white', {
                  'pt-site-banner desktop-nav:pt-site-header': siteBanner,
                  'desktop-nav:pt-nav': !siteBanner,
                })}
              >
                {nav ? (
                  <Navbar
                    primaryCta={{
                      text: nav.navPrimaryCtaText,
                      url: nav.navPrimaryCtaUrl,
                    }}
                    className="bg-white"
                    scrollTriggerClassName={cx({
                      '-mt-nav': !siteBanner,
                      '-mt-site-header': siteBanner,
                    })}
                    showBorder
                    siteBanner={siteBanner}
                    marketingBanner={marketingBanner?.active ? marketingBanner.message : undefined}
                  />
                ) : null}
                {subNavBar}
              </header>
            ) : null}

            <main
              id="content"
              className={cx(className, 'h-full', {
                'flex-grow': !centerMainContent,
              })}
            >
              {children}
            </main>
            {footer ? (
              <Footer
                footerSiteLinks={footer.footerSiteLinks}
                footerCurrentLocations={footer.footerCurrentLocations}
                footerFutureLocations={footer.footerFutureLocations}
                footerAdditionalLinksHeading={
                  footer.footerAdditionalLinksHeading
                }
                footerAdditionalLinks={footer.footerAdditionalLinks}
                footerConnectText={footer.footerConnectText}
              />
            ) : null}
          </>
        ) : null}
      </>
    </>
  )
}

export default Layout
